import { useEffect, useState } from 'react';
import '../styles/about.css';
import story from '../images/aboutus-collage@2x.png';
import mission from '../images/icon mission@2x.png';
import vision from '../images/icon vision@2x.png';
import belive from '../images/icon beliefs@2x.png';
import bubble from '../images/icon bubble@2x.png';
import hands from '../images/shaking-hands@2x.png';
import blossom from '../images/bullet-blossom.svg';
import Final from '../components/Final';
import { Link } from 'react-router-dom';
import Bios from '../data/bios.json';

function About() {
   const [tab, setTab] = useState(false);
   const [currentBio, setCurrentBio] = useState<number | null>(null);

   const openModal = (bioIndex: number) => {
      setCurrentBio(bioIndex);

      document.body.style.overflow = 'hidden';
   };

   const closeModal = (event?: any) => {
      if ((event && event.target.classList.contains('modal')) || event.target.classList.contains('close-btn')) {
         setCurrentBio(null);
         document.body.style.overflow = 'visible';
      }
   };

   const handleTab = () => {
      setTab(!tab);
   };


   type BioProps = {
      index: number;
   };
   const BioTemplate = (props: BioProps) => {
      return (
         <div
            className="person-story"
            onClick={() => {
               openModal(props.index);
            }}>
            <div>
               <p>
                  {Bios[props.index]?.name}
               </p>
               <p>{Bios[props.index]?.title}</p>
               <p>{Bios[props.index]?.bio}</p>
            </div>
            <img className="radius-3xl" width="100%" src={Bios[props.index]?.img} alt="bio_image" />
         </div>
      );
   };

   return (
      <>
         {currentBio !== null && Bios[currentBio] && (
            <div className="modal flex items-center center justify-center" onClick={closeModal}>
               <div className="modal-content">
                  <h1>{Bios[currentBio].name}</h1>
                  <h2>{Bios[currentBio]?.title}</h2>
                  <div className='flex gap-10'>
                     
                        <p dangerouslySetInnerHTML={{ __html: Bios[currentBio].bioFull.replace(/\r\n/g, '<br />') }} />
                        <div><img src={Bios[currentBio]?.img} alt="bio_image" /></div>
                     
                  </div>
                  <button className="close-btn" onClick={closeModal}>
                     +
                  </button>
               </div>
            </div>
         )}
         <div className="story about">
            <div className="w-50 flex flex-col gap-10">
               <h2 className="title text-left">Our Story</h2>
               <h4 className="subtitle text-left">The genesis of Labs4Free is rooted in Mike’s founding of Machaon Diagnostics, a state-of-the-art laboratory in Berkeley, California. </h4>
               <p>
                  Since he opened its doors in 2003, hardly a day goes by without a terribly sick patient calling for help. They say they can’t afford the lab tests their doctors have ordered. And the doctors say they can’t properly diagnose their illness and treat them without the tests. This is
                  one of the reasons a shocking 80% of patients with aHUS die needlessly. They don’t have access to diagnostic testing in a timely manner. These people are pregnant woman, fathers, sisters, brothers, mothers, typically only 30-60 years old. How is it possible that in an age where
                  some people can buy a ticket to outer space, others can’t afford testing of their own blood that could literally save their lives? Mike thinks this is downright wrong, so he founded Labs4Free, to make a change. Labs4Free raises funds from individuals, foundations, research and
                  academic institutions, associations, corporations, and even from other nonprofits. This money is used to cover the cost of laboratory testing for rare diseases and chronic disorders for patients in need.
               </p>
               <h4 className="subtitle text-left">Labs4Free removes financial barriers to life saving lab tests.</h4>
            </div>
            <div className="flex center items-center w-50">
               <img width="600px" height="661px" src={story} alt="story" />
            </div>
         </div>
         <div className="goals about">
            <div>
               <div className="top">
                  <img width="70px" height="70px" src={mission} alt="mission" />
                  <h3 className="title">Our Mission</h3>
               </div>
               <p>Our mission is to fund diagnostic and ongoing laboratory testing for rare diseases and chronic disorders for all people. We aim to reduce disparities in access to testing by partnering with organizations that work with underserved communities.</p>
            </div>
            <div>
               <div className="top">
                  <img width="70px" height="70px" src={vision} alt="vision" />
                  <h3 className="title">Our Vision</h3>
               </div>
               <p>Our vision is to see a world where everyone has access to laboratory tests. That testing be provided free of financial burdens to individuals and families. We envision a world where early and accurate identification of illness saves lives.</p>
            </div>
            <div>
               <div className="top">
                  <img width="70px" height="70px" src={belive} alt="belive" />
                  <h3 className="title">Our Beliefs</h3>
               </div>
               <p>
                  We believe that access to life saving health services is a human right. That people of all beliefs, ethnicities, sexes, gender identities, sexual orientations, national origins, and physical, mental, and emotional health, should be treated with kindness, and respect, and receive
                  the highest level of care available. There is enough wealth on the planet that no person should suffer or die waiting for a lab test.
               </p>
            </div>
         </div>
         <div className="values about">
            <div className="w-50 flex flex-col gap-10">
               <h2 className="title text-left">Our Values</h2>
               <h4 className="subtitle text-left">
                  We value and hold ourselves to the highest standards of <b>Integrity, Respect, and Empathy</b>. We embrace <b>Responsibility, Efficiency and Quality</b> in all our work.
               </h4>
               <h2 className="title text-left">Our Goals</h2>
               <p>We strive to offer a smooth functioning clear system for sick patients to apply and rapidly receive funding for their laboratory tests. Our goal is to contribute to a healthier world, one person, one family, one community at a time.</p>
               <h2 className="title text-left">Transparency / Accountability</h2>
               <p>
                  We believe organizational transparency is paramount for trust and understanding. In this spirit we share key data on our leadership, legal structure, financials, partners, and donors. We believe there is ALWAYS room for improvement in service and communication. Feel free to share
                  your thoughts on any and all parts of how we can better assist you.
               </p>
            </div>
            <div className="flex center items-center w-50">
               <img width="600px" height="661px" src={hands} alt="hands" />
            </div>
         </div>
         <div className="banner">
            <div>
               <img width="118px" height="97px" src={bubble} alt="bubble" />
               <p>Feel free to share your thoughts on any and all parts of how we can better assist you.</p>
            </div>
            <Link to="mailto:tara.williams@labs4free.org">
               <button className="tertiary">LET US KNOW</button>
            </Link>
         </div>
         <div className="leadership-container">
            <div className="flex flex-col items-center gap-5">
               <h2 className="title text-center">Our Leadership</h2>
               <p>Labs4Free is a minority founded and led nonprofit guided by a diverse Board of Directors and staff. We believe our differences and varying perspectives strengthen our organization. We come from diverse backgrounds, expertise, geographies, genders and ethnicities.</p>
            </div>
            <div className="leadership">
               <div className="mt-7">
                  <div className="flex items-end gap-1">
                     {/* <button className={tab ? 'tab-disable disable' : 'tab'} onClick={handleTab} disabled={!tab}>
                        <p>Board of Directors</p>
                     </button> */}
                     {/* <button className={tab ? 'tab' : 'tab-disable'} onClick={handleTab} disabled={tab}>
                        <p>Executive Staff</p>
                     </button> */}
                  </div>
                  <div className="tab-bar" />
               </div>
            </div>
            <div className="person mob">
               {tab ? (
                  <>
                     <div className="flex flex-col mt-5 mb-5 items-center gap-10">
                        <h2 className="title">Executive Staff</h2>
                     </div>
                     <div className="leadership-grid">
                        <BioTemplate index={0} />
                        <BioTemplate index={1} />
                     </div>
                  </>
               ) : (
                  <>
                     <div className="flex flex-col mt-5 mb-5 items-center gap-10">
                        <h2 className="title">Board of Directors</h2>
                     </div>
                     <div className="leadership-grid">
                        <BioTemplate index={0} />
                        <BioTemplate index={2} />
                        <BioTemplate index={3} />
                     </div>
                  </>
               )}
            </div>
         </div>
         <div className="general">
            <div className="flex flex-col gap-10">
               <h3 className="title text-left">Accreditation & Transparency</h3>
               <ul className="flex flex-col gap-5">
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">Federal</p>
                           <p className="bottom-text">IRS form 990 - Verification of Good Standing</p>
                        </div>
                     </div>
                  </li>
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">State of California</p>
                           <p className="bottom-text">Articles of Incorporation</p>
                        </div>
                     </div>
                  </li>
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">Registry of Charitable Trusts</p>
                           <p className="bottom-text">Office of Attorney General, State of California</p>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
            <div className="flex flex-col gap-10">
               <h3 className="title text-left">Financials</h3>
               <ul className="flex flex-col gap-5">
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">More info coming soon…</p>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
            <div className="flex flex-col gap-10">
               <h3 className="title text-left">Partnerships</h3>
               <ul className="flex flex-col gap-5">
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">Machaon Diagnostics</p>
                        </div>
                     </div>
                  </li>
                  <li>
                     <div className="flex gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <div className="flex flex-col items-start">
                           <p className="head-text">More partnerships coming soon…</p>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
            {/* <div className="flex flex-col gap-10">
          <h3 className="title text-left">Affiliations</h3>
          <ul className="flex flex-col gap-5">
            <li>
              <div className="flex gap-4">
                <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                <div className="flex flex-col items-start">
                  <p className="head-text">Fidelity Charitable</p>
                </div>
              </div>
            </li>
            <li>
              <div className="flex gap-4">
                <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                <div className="flex flex-col items-start">
                  <p className="head-text">Vanguard Charitable</p>
                </div>
              </div>
            </li>
            <li>
              <div className="flex gap-4">
                <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                <div className="flex flex-col items-start">
                  <p className="head-text">Schwab Charitable</p>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
            {/* <div className="flex flex-col gap-10">
          <h3 className="title text-left">Memberships</h3>
          <ul className="flex flex-col gap-5">
            <li>
              <div className="flex gap-4">
                <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                <div className="flex flex-col items-start">
                  <p className="head-text">More info coming soon…</p>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
            {/* <div className="flex flex-col gap-10">
          <h3 className="title text-left">Funding</h3>
          <ul className="flex flex-col gap-5">
            <li>
              <div className="flex gap-4">
                <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                <div className="flex flex-col items-start">
                  <p className="head-text">More info coming soon…</p>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
         </div>

         <Final />
      </>
   );
}

export default About;
